<template>
  <div class="daily-report">
    <!--header-->
    <header class="daily-report__header">
      <div class="daily-report__info">
        <h2>美療總表</h2>
        <router-link class="daily-report__btn-medical" to="/daily-report-medical">醫療總表</router-link>
      </div>
      <div class="daily-report__date">
        <button class="daily-report__btn-prev" @click="addDay(-1)"></button>
        <button class="daily-report__btn-next" @click="addDay(1)"></button>
        <datepicker class="reservation-time__datepicker daily-report__btn-select-date" inputFormat="yyyy.MM.dd" v-model="date" />
        <!-- <span class="daily-report__current-date">2020/10/26 - 31</span> -->
        <!-- <button class="daily-report__btn-select-date">選擇日期</button> -->
      </div>
      <front-desk-menu :active-item="'daily-report-beauty'" />
    </header>
    <!--header-->
    <main class="daily-report__main">
      <div class="daily-report__outpatient-wrap" id="outpatient-wrap">
        <span>時間</span>
        <!-- <span v-for="name in staffNames" :key="name">{{name}}</span> -->
        <!-- <span v-for="i in (10-staffNames.length)" :key="i"></span> -->
        <span v-for="c in maxReservationCount" :key="c">{{c}}</span>
        <!-- <span v-for="d in 8-maxReservationCount" :key="d"></span> -->
      </div>
      <div class="daily-report__new-period-wrap">
        <div class="daily-report__new-period-row" v-for="row in rows" :key="row">
          <div class="daily-report__new-period">
            <span>{{row.time}}</span>
          </div>
          <template v-for="r in row.reservations" :key="r">
            <DailyReportBeautyItem :reservation="r" @cancel="onCancel"/>
          </template>
          <div class="daily-report__new-period" v-for="b in maxReservationCount-row.reservations.length" :key="b"></div>
        </div>
      </div>
    </main>
    <loading :isActive="isLoading" />
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker'
import { apiGetDailyReportNew, apiGetTimeSlots, apiCancel } from '../api/v1/reservations'
import { formatDate, formatTimeHHmm, formatTWBirthDay } from '../utils/utils'
import Loading from '../components/Loading.vue'
import FrontDeskMenu from '../components/FrontDeskMenu.vue'
import DailyReportBeautyItem from '../components/DailyReportBeautyItem.vue'

export default {
  name: 'DailyReportBeauty',
  components: {
    Datepicker,
    Loading,
    FrontDeskMenu,
    DailyReportBeautyItem
  },
  watch: {
    searchParams () {
      this.loadData()
    },
    times (val) {
      const rows = []
      val.forEach(t => {
        const obj = {
          time: t,
          reservations: this.findReservationsInTimeSlot(t)
        }
        this.maxReservationCount = (obj.reservations.length > this.maxReservationCount ? obj.reservations.length : this.maxReservationCount)
        rows.push(obj)
      })
      this.rows = rows
    },
    reservations (val) {
      if (val.length > 0) {
        const rows = []
        this.times.forEach(t => {
          const obj = {
            time: t,
            reservations: this.findReservationsInTimeSlot(t)
          }
          this.maxReservationCount = (obj.reservations.length > this.maxReservationCount ? obj.reservations.length : this.maxReservationCount)
          rows.push(obj)
        })
        this.rows = rows
      }
    }
  },
  data () {
    return {
      keyword: '',
      reservations: [],
      staffNames: [],
      staffIds: [],
      times: [],
      rows: [],
      interval: 70,
      date: new Date(),
      isLoading: false,
      maxReservationCount: 0
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    searchParams () {
      return [this.date]
    }
  },
  methods: {
    loadData () {
      this.isLoading = true
      this.loadTimeSlots()
      this.loadDailyReport()
      this.isLoading = false
    },
    async loadTimeSlots () {
      const data = await apiGetTimeSlots()
      this.interval = data.beautifyInterval
      this.maxReservationCount = 0
      this.times = this.date.getDay() === 6 ? data.beautifySaturday : data.beautifyWeekday
    },
    async loadDailyReport () {
      const params = { is_medical: false, date: formatDate(this.date) }
      this.staffNames = []
      this.staffIds = []
      this.reservations = []
      this.reservations = await apiGetDailyReportNew(params)
      this.findStaffs()
    },
    findStaffs () {
      if (this.reservations) {
        this.reservations.forEach(r => {
          if (!this.staffIds.includes(r.treatmentStaffId)) {
            this.staffIds.push(r.treatmentStaffId)
            this.staffNames.push(r.treatmentStaffName)
          }
        })
      }
    },
    findReservationsInTimeSlot (time) {
      const date1 = new Date(`${formatDate(this.date, '/')} ${time}`)
      const date2 = new Date(date1.getTime() + this.interval * 60000)
      const time2 = formatTimeHHmm(date2)
      // return this.staffIds.map(staffId => this.reservations.find(r => r.treatmentStart >= time && r.treatmentStart < time2 && r.treatmentStaffId === staffId))
      return this.reservations.filter(r => r.treatmentStart >= time && r.treatmentStart < time2)
    },
    addDay (day) {
      var date = new Date(this.date)
      date.setDate(date.getDate() + day)
      this.date = date
    },
    formatBirthDate (bdate) {
      return formatTWBirthDay(bdate)
    },
    // 取消預約
    onCancel (reservationId) {
      const params = {
        reservationId: reservationId
      }
      apiCancel(params).then(data => {
        if (data.error) {
          console.log(data.error.message)
          this.$swal('取消失敗', data.error.message, 'warning')
        } else {
          console.log(data.message)
          this.$swal('已取消', '', 'success')
          this.reservations = this.reservations.filter(r => r.reservationId !== reservationId)
        }
      }).catch(e => {
        console.log(e)
        this.$swal('取消失敗', e, 'error')
      })
    }
  }
}
</script>

<style>

</style>
